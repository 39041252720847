body {
	font-family: 'Montserrat';
}

.next-btn {
	position: absolute;
	right: -20px;
	top: 50%;
	width: 30px;
	height: 30px;
	cursor: pointer;
}


.prev-btn {
	position: absolute;
	left: -10px;
	top: 50%;
	width: 30px;
	height: 30px;
	cursor: pointer;
}

.next-btn-comment {
	position: absolute;
	right: -15px;
	top: 50%;
	width: 30px;
	height: 30px;
	cursor: pointer;
}


.prev-btn-comment {
	position: absolute;
	left: -30px;
	top: 50%;
	width: 30px;
	height: 30px;
	cursor: pointer;
}

.slick-initialized .slick-slide > div {
	display: flex;
	justify-content: center;
}

.user-box {
	max-width: 272px;
}

.MuiInputBase-root {
	border-radius: 15px !important;
}

.hide-scrollbar::-webkit-scrollbar {
	display: none;
}

.hide-scrollbar {
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
}

.tablex {
	border:1px solid #D9D9D9;
	border-radius: 20px;
}

@media all and (max-width: 967px) {
	.hide-tablet {
		display: none !important;
	}
}

@media all and (max-width: 550px) {
	.hide-mobile {
		display: none !important;
	}

	.slick-initialized .slick-slide {
		margin: 0 auto !important;
		float: none;
	}
}
